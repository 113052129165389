module.exports = {
  siteTitle: 'Leaders for Climate Action',
  siteTitleAlt: 'Leaders for Climate Action', // This allows an alternative site title for SEO schema.
  publisher: 'LFCA GmbH', // Organization name used for SEO schema
  siteDescription:
    'A global community that drives sustainability in organizations & beyond.',
  siteUrl: 'https://lfca.earth', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  author: 'Timo Müller', // Author for RSS author segment and SEO schema
  authorUrl: '', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: 'Leaders4CA', // Change for Twitter Cards
  shortTitle: 'LFCA', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logos/share.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareText:
    'Join our global community driving sustainability in organizations & beyond.',
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logos/logo-512.png', // Logo used for SEO, RSS, and App manifest
  favicon: '/logos/favicon.png',
  backgroundColor: '#e9e9e9', // Used for Offline Manifest
  themeColor: '#121212', // Used for Offline Manifest
  copyright: 'Copyright © Leaders for Climate Action', // Copyright string for the RSS feed
  defaultLangKey: 'en',
  defaultRegionKey: 'ALL',
}
