import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React, { useCallback, useEffect } from 'react'
import {
  ADDITIONAL_PROPERTIES,
  EVENTS,
  NAVIGATION_EVENTS,
  useAnalytics,
} from '../hooks/useAnalytics'
import { globalHistory } from '@reach/router'
import { AnalyticsProvider } from '../services/rudderstack'
import { CookiesProvider } from 'react-cookie'
import { useTrackingCode } from 'react-hubspot-tracking-code-hook'

const queryClient = new QueryClient()

const TrackedPage = ({ children }) => {
  const client = useAnalytics()
  // Hubspot tracking code
  const { setPathPageView, setContentType } = useTrackingCode()

  const initalPageView = useCallback(() => {
    // Track initial page
    client.track(EVENTS.PAGE_VIEW, {
      path: window.location.pathname,
    })

    // Hubspot tracking code
    setPathPageView(window.location.pathname)
    setContentType('landing-page')
  }, [client])

  useEffect(() => {
    initalPageView()
  }, [initalPageView])

  useEffect(() => {
    const createThirtySecondsTimeoutTracker = () =>
      setTimeout(() => {
        client.track(NAVIGATION_EVENTS.DWELL_TIME, {
          [ADDITIONAL_PROPERTIES.PATH]: window.location.pathname,
          [ADDITIONAL_PROPERTIES.DURATION]: '30000',
        })
      }, 30000)

    // Track after 30 seconds on page
    let thirty_seconds_timer = createThirtySecondsTimeoutTracker()

    // Listen for state changes
    const unsubscribeHistory = globalHistory.listen((props) => {
      client.track(EVENTS.PAGE_VIEW, {
        path: props?.location.pathname,
      })
      // Reset 30 seconds timer
      clearTimeout(thirty_seconds_timer)
      thirty_seconds_timer = createThirtySecondsTimeoutTracker()
    })

    return () => {
      unsubscribeHistory()
      clearTimeout(thirty_seconds_timer)
    }
  }, [client])

  return <>{children}</>
}

export const RootElement = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AnalyticsProvider>
        <CookiesProvider>
          <TrackedPage>{children}</TrackedPage>
        </CookiesProvider>
      </AnalyticsProvider>
    </QueryClientProvider>
  )
}
