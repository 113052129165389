import React, { createContext, useEffect, useState } from 'react'

import { isBrowser, isDev } from '../utils'

export const COMPANY_ID_KEY = 'Company ID'
export const REFERRER_UID_KEY = 'Referrer User ID'
export const UTM_TAGS_KEY = 'UTM Tags'

export const AnalyticsContext = createContext()

export const AnalyticsProvider = ({ children }) => {
  const [client, setClient] = useState()

  useEffect(() => {
    async function rudderInitialize() {
      const rudderClient = await import('rudder-sdk-js')
      setClient(rudderClient)

      rudderClient.load(
        process.env.GATSBY_RUDDERSTACK_WRITE_KEY,
        process.env.GATSBY_RUDDERSTACK_DATA_PLANE_URL,
        {
          anonymousIdOptions: {
            autoCapture: {
              enabled: true,
              source: 'segment',
            },
          },
          integrations: { All: true },
        }
      )

      rudderClient.ready(() => {
        if (isDev) {
          console.info('Rudderstack is ready!!!')
        }
      })
    }

    rudderInitialize()
  }, [])

  return (
    <AnalyticsContext.Provider value={client}>
      {children}
    </AnalyticsContext.Provider>
  )
}

/**
 * A intermediate analytics client that can be used as long as rudderstack is not initialised, yet.
 * It will push all events to `window.rudderanalytics` which is a known property by rudderstack
 * that will be checkend and executed after loading the SDK.
 */
export const bufferClient = {
  addToBuffer(methodName, ...args) {
    if (!isBrowser()) {
      throw new Error('Analytics can only be called in browser env.')
    }

    if (!window.rudderanalytics) {
      window.rudderanalytics = []
    }
    window.rudderanalytics.push(
      [methodName].concat(Array.prototype.slice.call(args))
    )
  },
  identify() {
    // Nothing to do here
    // since firebase provider is (re)identifiying once the analytics provider is set
  },
  isBufferClient: true,
  page(...args) {
    this.addToBuffer('page', ...args)
  },
  reset() {
    this.addToBuffer('reset')
  },
  track(...args) {
    this.addToBuffer('track', ...args)
  },
}
