const { defaultLangKey, defaultRegionKey } = require('./siteConfig')
// functions that are shared between gatsby node js code
// and components, need to be written in module.export way

const getPrefix = ({ regionCode, langCode, availableLangs }) => {
  const isDefaultRegion = regionCode === defaultRegionKey
  const isDefaultLanguage = langCode === defaultLangKey
  const isDefault = isDefaultRegion && isDefaultLanguage
  // the default region and lang combo should be on root
  if (isDefault) return ``

  if (availableLangs.length <= 1 && !isDefaultRegion) {
    // if only one language is available for the region
    // use the ALL_ indicator to render it for everyone
    // from that region
    return `ALL_${regionCode}`
  }

  return `${langCode}_${regionCode}`
}

const eventCategoriesMap = {
  EDUCATIONAL_COURSE: {
    name: 'Educational Course',
    sorthWeight: 1,
    rootPath: 'course',
    ctaTitle: 'View course details',
  },
  WORKSHOP: {
    name: 'Workshop',
    rootPath: 'workshop',
    sorthWeight: 2,
    ctaTitle: 'View workshop details',
  },
  LEARNING_SESSION: {
    name: 'Learning Session',
    sorthWeight: 3,
    cta: 'View learning session',
  },
  ONBOARDING_CALL: {
    name: 'Onboarding Call',
    sorthWeight: 4,
  },
  ONBOARDING_COURSE: {
    name: 'Onboarding Course',
    sorthWeight: 5,
  },
  COMMUNITY_EVENTS: {
    name: 'Community Events',
    sorthWeight: 6,
  },
}

exports.eventCategoriesMap = eventCategoriesMap

const getAllAvailableLanguages = (regions) => {
  const result = []
  regions.forEach((region) => {
    region.languages.forEach((lang) => {
      const prefix = getPrefix({
        regionCode: region.isoCode,
        langCode: lang.isoCode,
        availableLangs: region.languages,
      })
      let hasDifferentCanonical = false
      let canonical = null
      // by default the canonical url is same as the prefix, however
      // to prevent duplicate content pages like de_en (same as /)
      // we need a canonical tag linking to the original source
      const isNotRegionsDefaultLang =
        region.defaultLanguage.isoCode !== lang.isoCode
      const otherRegionWithThisDefaultLang = regions.find(
        (region) => region.defaultLanguage.isoCode === lang.isoCode
      )

      if (isNotRegionsDefaultLang && !!otherRegionWithThisDefaultLang) {
        canonical = getPrefix({
          regionCode: otherRegionWithThisDefaultLang.isoCode,
          langCode: otherRegionWithThisDefaultLang.defaultLanguage.isoCode,
          availableLangs: otherRegionWithThisDefaultLang.languages,
        })
        hasDifferentCanonical = true
      }
      result.push({
        prefix: prefix,
        code: lang.isoCode,
        canonical: canonical,
        hasDifferentCanonical: hasDifferentCanonical,
      })
    })
  })
  return result
}

exports.getAllAvailableLanguages = getAllAvailableLanguages

const getFullCountryObjects = (continents, countryCodeOnly) => {
  const fullCountryObjects = {}
  for (const continent of continents) {
    if (!continent?.country) continue
    for (const country of continent?.country) {
      const fullIsoCode = `${continent.isoCode}-${country.isoCode}`
      const key = countryCodeOnly ? country.isoCode?.toLowerCase() : fullIsoCode
      fullCountryObjects[key] = {
        name: country.name,
        isoCode: country.isoCode?.toLowerCase(),
        fullIsoCode: fullIsoCode,
      }
    }
  }
  return fullCountryObjects
}

exports.getFullCountryObjects = getFullCountryObjects

const MEMBERS_SORT = {
  default: {
    title: 'Default',
    type: 'default',
    sort: ['customSort'],
    order: ['desc'],
  },
}

exports.MEMBERS_SORT = MEMBERS_SORT

const addCustomSortWeight = (users) => {
  // custom sort algorithm
  // use country > diversity
  // use program?
  const COMPANY_BUCKET_SIZE = 1
  const COUNTRY_BUCKET_SIZE = 5
  const COUNTRY_BONUS = 6
  const PICTURE_BONUS = 2
  const COMPANY_BONUS = 1

  // company exception due to bad photos or similar
  const DOWNRATE_COMPANIES = [
    'Lanasia',
    'uPledge',
    'CarbonLink',
    'Meilleurs Agents',
  ]
  // mix officers and leaders

  // add custom sort weight for users
  const countryBucket = {}
  const companyBucket = {}

  const withSortWeight = users.map((user) => {
    const hasUploadedPhoto =
      user.picture?.indexOf('placeholder') > -1 ? 0 : PICTURE_BONUS
    const completedCompanyActionsCount =
      user.company?.completedCompanyActions?.length
    const activity = completedCompanyActionsCount

    // the first 5 members of a country, get a bonus
    const companyCountry = user.company?.country
    if (typeof countryBucket[companyCountry] === 'undefined') {
      countryBucket[companyCountry] = COUNTRY_BUCKET_SIZE
    } else if (countryBucket[companyCountry] > 0) {
      countryBucket[companyCountry] = countryBucket[companyCountry] - 1
    }

    const isInCountryBucket =
      countryBucket[companyCountry] > 0 ? COUNTRY_BONUS : 1

    // the first member of a company, gets a bonus
    if (typeof companyBucket[user.country] === 'undefined') {
      companyBucket[user.country] = COMPANY_BUCKET_SIZE
    } else if (companyBucket[user.country] > 0) {
      companyBucket[user.country] = companyBucket[user.country] - 1
    }
    const isInCompanyBucket =
      companyBucket[user.country] > 0 ? COMPANY_BONUS : 0.1

    const customSort =
      isInCountryBucket * // either 1 or 5 => diversity factor
      (activity || 1) * // between 1 and ca. 15 => activity in app
      isInCompanyBucket * // either 0.1 or 1 => prevents users from same company next to each other
      (user?.sortWeight || 1) * // between 1 and 100 => manual sort weight
      hasUploadedPhoto * // can be 0 => only knockout criteria
      (DOWNRATE_COMPANIES.indexOf(user?.company?.name) > -1 ? 0 : 1) // manual hardcoded removal

    return {
      ...user,
      customSort: customSort,
      activity: activity * hasUploadedPhoto,
    }
  })

  return withSortWeight
}

exports.addCustomSortWeight = addCustomSortWeight
