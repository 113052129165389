import React from 'react'
import climateHero from '../assets/icons/climateHero.svg'
import contributing from '../assets/icons/contributing.svg'
import certifiedGoals from '../assets/icons/certifiedGoals.svg'
import activeReduction from '../assets/icons/activeReduction.svg'
import fullScope from '../assets/icons/fullScope.svg'
import { eventCategoriesMap } from './shared'

export const MOBILE_BREAKPOINT = 768
export const TABLET_BREAKPOINT = 992
export const SUPPORT_MAIL = 'membership@lfca.earth'
export const PROGRAMS_SUPPORT_MAIL = 'lucy@lfca.earth'
export const ONBOARDING_LINK = 'https://app.lfca.earth/onboarding'

export const isContentfulStaging = () => {
  if (process.env.CF_URL === 'preview.contentful.com') {
    return true
  } else return false
}

export const isDev = process.env.NODE_ENV === 'development'

export const asSafeAssetUrl = (url) => {
  // if starts with http or https, return as is
  if (url.startsWith('http')) return url
  // if starts with //, add https:
  if (url.startsWith('//')) return `https:${url}`
  else return url
}

export function arrToObj(array, key) {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item,
    }
  }, initialValue)
}

export const CLOUDINARY_CLOUD_NAME = 'dhpk1grmy'

export function getImageName(image) {
  if (!image) return false
  const imageUrlParts = image.split('/')
  return imageUrlParts[imageUrlParts.length - 1] || image
}

export const getMemberImage = (imageName, width = 500, height = 600) => {
  if (imageName)
    return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/w_${width},h_${height},c_fill/Members/${imageName}`
  else
    return `https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/v1602835665/members/ef5soqw6ddrsggtf8nzr.jpg`
}

export function chunk(array, size) {
  const chunkedArr = []
  for (let i = 0; i < array.length; i++) {
    const last = chunkedArr[chunkedArr.length - 1]
    if (!last || last.length === size) {
      chunkedArr.push([array[i]])
    } else {
      last.push(array[i])
    }
  }
  return chunkedArr
}

export const renderAsHtml = (el) => {
  if (!el) return ''

  const htmlRemark = el.childMarkdownRemark.html

  if (!htmlRemark) return ''

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: htmlRemark,
      }}
    />
  )
}

export function getBrowserLanguage() {
  if (typeof navigator === `undefined`) {
    return 'en-US'
  }

  const lang = navigator && navigator.language
  if (!lang) return 'en-US'

  return lang
}

export const isBrowser = () => typeof window !== 'undefined'

export const filterByLocale = (arr, langKey) => {
  return arr.reduce((filtered, node) => {
    if (node.node_locale === langKey) {
      filtered.push({
        node_locale: node.node_locale,
        ...node,
      })
    }
    return filtered
  }, [])
}

export const getLangAndRegionByUrl = (url) => {
  const { defaultLangKey, defaultRegionKey } = require('../utils/siteConfig')
  const defaultSetting = [defaultLangKey, defaultRegionKey]

  if (!url) return defaultSetting

  const urlParts = url.split('/')
  const urlPartLang = urlParts[1]
  const regex = /^([a-zA-Z]{2,3})_([a-zA-Z]{2,3})$/g

  if (urlPartLang.match(regex)) {
    const [langKey, regionKey] = urlPartLang.split('_')
    return [langKey, regionKey]
  } else {
    return defaultSetting
  }
}

export const getPrefixByLangAndRegion = (
  langKey,
  regionKey,
  availableLangs
) => {
  const { defaultLangKey, defaultRegionKey } = require('../utils/siteConfig')
  const isDefaultLang = langKey === defaultLangKey
  const isDefaultRegion = regionKey === defaultRegionKey
  const isOnlyOneLanguage = availableLangs.length <= 1

  if (isDefaultLang && isDefaultRegion) return ''
  // if only one language is available use ALL
  if (isOnlyOneLanguage) return `ALL_${regionKey}`
  // return full key
  return `${langKey}_${regionKey}`
}

export const getDarkMode = (colorCode) => {
  const color = '' + colorCode
  const isHEX = color.indexOf('#') === 0
  const isRGB = color.indexOf('rgb') === 0
  let r, g, b
  if (isHEX) {
    const hasFullSpec = color.length === 7
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g)
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16)
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16)
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16)
    }
  }
  if (isRGB) {
    const m = color.match(/(\d+){3}/g)
    if (m) {
      r = m[0]
      g = m[1]
      b = m[2]
    }
  }
  let luminance
  let isDarkMode = ''
  if (typeof r !== 'undefined') {
    luminance = (r * 299 + g * 587 + b * 114) / 1000
    if (luminance < 127) {
      isDarkMode = 'dark-mode'
    }
  }
  return isDarkMode
}

export const getCarouselSettings = (layout) => {
  const defaultSettings = {
    arrows: true,
    dots: false,
    infinite: false,
  }
  switch (layout) {
    case '2-col':
      return {
        ...defaultSettings,
        slidesToScroll: 2,
        slidesToShow: 2,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      }
    case '3-col':
      return {
        ...defaultSettings,
        slidesToScroll: 3,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
        cardType: 'course',
      }
    case '4-col':
      return {
        ...defaultSettings,
        slidesToScroll: 4,
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: TABLET_BREAKPOINT,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      }
    case '6-col':
      return {
        ...defaultSettings,
        slidesToScroll: 6,
        slidesToShow: 6,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: TABLET_BREAKPOINT,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
        ],
      }
    case 'course-preview':
      return {
        ...defaultSettings,
        slidesToScroll: 3,
        slidesToShow: 3,
        responsive: [
          {
            breakpoint: MOBILE_BREAKPOINT,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
        cardType: 'course',
      }
    case 'learning-session-preview':
      return {
        ...defaultSettings,
        slidesToScroll: 1,
        slidesToShow: 1,
        rows: 2,
        cols: 5,
        cardType: 'learning-session',
      }
    default:
      return {}
  }
}

export const inChunks = (array, parts) => {
  const copy = [...array]
  let result = []
  for (let i = parts; i > 0; i--) {
    result.push(copy.splice(0, Math.ceil(copy.length / i)))
  }
  return result
}

export const getBreakPoints = (layout) => {
  switch (layout) {
    case 'floating-220':
      return { animation: 'move', size: 220, rows: 1 }
    case 'floating-190':
      return { animation: 'move', size: 190, rows: 1, bg: 'white' }
    case '2-col':
      return { xs: 12, md: 12, lg: 12 }
    case '2-col-fixed':
      return { xs: 12, md: 12, lg: 12 }
    case '3-col':
      return { xs: 24, lg: 8 }
    case '3-col-floating':
      return { cols: 3, type: 'stacked' }
    case '3-col-offset-2':
      return { xs: 24, lg: { span: 6 }, lgOffset: 3 }
    case '4-col-offset-2':
      return {
        xs: 12,
        md: 6,
        lg: { span: 5, offset: 2 },
        xl: { span: 4, offset: 4 },
      }
    case '4-col':
      return { xs: 24, md: 12, lg: 6 }
    case '6-col':
      return { xs: 24, lg: 4 }
    case '8-col':
      return { xs: 24, md: 6, lg: 3 }
    case 'block':
      return {
        gutter: 16,
        xs: 1,
      }
    case 'xl':
      return {
        gutter: 16,
        xs: 2,
        sm: 4,
        md: 4,
        lg: 6,
        xl: 6,
        xxl: 6,
      }
    case 'lg':
      return {
        gutter: 16,
        xs: 2,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      }
    default:
      return {}
  }
}

export const getColLayouts = (layout) => {
  switch (layout) {
    case '2-col':
      return [
        { xs: 24, md: 12 },
        { xs: 24, md: 12 },
      ]
    case '2-col-text-right-aligned':
      return [
        { xs: 24, md: 12 },
        { xs: 24, md: 12 },
        { textAlign: 'right', direction: 'rtl' },
      ]
    case '2-col-small':
      return [
        { xs: 24, md: 8 },
        { xs: 24, md: 16 },
      ]

    default:
      return []
  }
}

export const isHex = (string) => {
  if (!string) return false
  if (string.substr(0, 1) === '#') return true
  else return false
}

export const getMarkdown = (content, langKey) => {
  if (!content) return ''
  return content[langKey] || content.en
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const ACHIEVEMENTS_MAP = {
  climateHero: {
    text: 'Climate Hero',
    color: '#62EF8F',
    textColor: '#014752',
    Icon: climateHero,
    description: 'Sustainable business model',
  },
  contributing: {
    text: 'Contributing to global net 0',
    color: '#62EF8F',
    textColor: '#014752',
    Icon: contributing,
    description: 'Investing in carbon removal or offset projects',
  },
  certifiedGoals: {
    text: 'Certified goals',
    color: '#62EF8F',
    textColor: '#014752',
    Icon: certifiedGoals,
    description: 'Longterm goals aligned with 1.5 degrees C',
  },
  activeReduction: {
    text: 'Active reduction',
    color: '#62EF8F',
    textColor: '#014752',
    Icon: activeReduction,
    description: 'Implemented more than 5 reduction measures',
  },
  fullScope: {
    text: 'Full scope',
    color: '#62EF8F',
    textColor: '#014752',
    Icon: fullScope,
    description: 'Measured full scope 3 footprint',
  },
  personal: {
    text: 'Personal Pledge',
    color: '#A8C3F8',
    textColor: '#FFF',
    Icon: climateHero,
    description: 'Fulfilled personal pledge',
  },
}

export const COMPANIES_SORT = {
  alphabetically: {
    title: 'Alphabetically',
    type: 'alphabetically',
    sort: ['name'],
    order: ['asc'],
  },
  impact: {
    title: 'Size',
    type: 'size',
    sort: ['employeeCount', 'badgesCount'],
    order: ['desc', 'desc'],
  },
}

export const getBadges = (actions = [], tags = []) => {
  let badgesCount = 0
  const climateTech = tags.find((t) => t === 'climate tech')
  if (climateTech) badgesCount++
  const certifiedGoals = actions.find((a) => a.contentId === 'sbti')
  if (certifiedGoals) badgesCount++
  const activeReduction = actions.length > 4
  if (activeReduction) badgesCount++
  const fullScope = actions.find(
    (a) => a.contentId === 'completeClimateNeutrality'
  )
  if (fullScope) badgesCount++
  const contributing = actions.find((a) => a.contentId === 'offsetEmissions')
  if (contributing) badgesCount++
  const personalPledge = actions.find((a) => a.contentId === 'personalPledge')
  if (personalPledge) badgesCount++

  return {
    badges: {
      personal: Boolean(personalPledge),
      certifiedGoals: Boolean(certifiedGoals),
      activeReduction: activeReduction,
      fullScope: Boolean(fullScope),
      contributing: Boolean(contributing),
      climateHero: Boolean(climateTech),
    },
    badgesCount: badgesCount,
  }
}

export const SORT_OPTIONS = {
  default: {
    title: 'Impact',
    type: 'impact',
    sort: ['impactValue'],
    order: ['desc'],
  },
  alphabetically: {
    title: 'Alphabetically',
    type: 'alphabetically',
    sort: ['title'],
    order: ['asc'],
  },
}

const DEFAULT_GROUP = 'All Actions'
export const groupByTags = (actions) => {
  if (!actions) return {}
  const allActions = []
  const res = Object.keys(actions).reduce((acc, key, i) => {
    const val = actions[key]
    if (!val.tags) return acc

    for (const tag of val.tags) {
      if (!acc[tag.name]) {
        acc[tag.name] = { data: [val], sortingWeight: tag.sortingWeight || 0 }
      } else {
        acc[tag.name].data.push(val)
      }
      allActions.push(val)
    }
    return acc
  }, {})
  const asArray = Object.keys(res).map((key) => ({ ...res[key], name: key }))
  const uniqueAll = {
    data: [...new Set(allActions)],
    sortingWeight: 1000,
    name: DEFAULT_GROUP,
  }
  const allGroups = [uniqueAll, ...asArray]
  return allGroups
}

export const groupByRootCategory = (
  actions,
  rootCategoryLookUp,
  rootMetaData
) => {
  if (!actions) return {}

  const res = Object.keys(actions).reduce((acc, key, i) => {
    const val = actions[key]
    if (!val.tags) return acc

    const [firstTag] = val.tags || []
    const rootCategory = rootCategoryLookUp[firstTag?.categoryId || '']

    if (!acc[rootCategory]) {
      acc[rootCategory] = [val]
    } else {
      acc[rootCategory].push(val)
    }

    return acc
  }, {})

  const asArray = Object.keys(res)
    .map((key) => {
      return { data: res[key], name: key }
    })
    .sort(
      (a, b) =>
        (rootMetaData[b?.name]?.sortWeight || 0) -
        (rootMetaData[a?.name]?.sortWeight || 0)
    )

  return asArray
}

export const getOptionAsNumber = (options, key) => {
  try {
    const option = options?.find((o) => o.key === key)
    const asNumber = Number(option.value?.childMarkdownRemark.rawMarkdownBody)
    return asNumber
  } catch (error) {
    // do nothing
    return null
  }
}

export const SM_BREAKPOINT = 767
export const MD_BREAKPOINT = 992
export const LG_BREAKPOINT = 1200
export const XL_BREAKPOINT = 1441
export const MOBILE = 'mobile'
export const DESKTOP = 'desktop'

export const mapEventCategory = (category) => {
  return (
    eventCategoriesMap[category] ||
    eventCategoriesMap[Object.keys(eventCategoriesMap)[0]]
  )
}

export const getLessons = (recurrences) =>
  recurrences?.filter(
    (recurrence) => recurrence.type === 'Lesson' || !recurrence.type
  )

export const getCourseLength = (recurrences) => {
  const lessons = getLessons(recurrences)
  const duration = lessons ? lessons.length : 0
  return duration
}

export const getAllInstructors = (recurrences) => {
  if (!recurrences) return []

  const allInstructors = recurrences.reduce((acc, recurrence) => {
    const instructors = recurrence.instructors || []
    return [...acc, ...instructors]
  }, [])

  // remove duplicates
  return [...new Set(allInstructors)]
}

export const getFirstRecurrenceStartDate = (recurrences) => {
  const [firstRecurrence] = recurrences || []

  if (!firstRecurrence) return null
  return new Date(firstRecurrence?.start)
}

export const getFirstRecurrenceEndDate = (recurrences) => {
  const [firstRecurrence] = recurrences || []

  if (!firstRecurrence) return null
  return new Date(firstRecurrence?.end)
}

export const getOptionsValue = (options, key) => {
  return options.find((o) => o.key === key)?.value?.childMarkdownRemark
    ?.rawMarkdownBody
}

export const splitIntoChunks = (a, size) =>
  Array.from(new Array(Math.ceil(a.length / size)), (_, i) =>
    a.slice(i * size, i * size + size)
  )

export const capitalizeEveryWord = (words) => {
  const wordsArray = words.split(' ')

  for (let i = 0; i < wordsArray.length; i++) {
    wordsArray[i] = capitalizeFirstLetter(wordsArray[i])
  }

  return wordsArray.join(' ')
}

export const replaceVars = (string, vars) => {
  // the string can contain "{{var}}" or "{{ var }}"
  // replace those with the vars passed in
  let newString = string
  const regex = /{{\s*([a-zA-Z0-9]+)\s*}}/g
  let match = regex.exec(string)
  while (match != null) {
    const varName = match[1]
    const varValue = vars[varName]
    newString = newString.replace(match[0], varValue)
    match = regex.exec(string)
  }
  return newString
}

export const downloadImageAsBlob = async (dataurl, fileName) => {
  const response = await fetch(dataurl)
  const blob = await response.blob()

  // check file extension from url
  const extension = dataurl.split('.').pop()

  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = `${fileName}.${extension}`
  link.click()
}
